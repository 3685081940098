<template>
  <v-card flat>
    <v-card-title>Datos profesionales</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" lg="8">
          <v-textarea
            v-model="formData.comments"
            label="Comentarios"
            rows="8"
            dense
            auto-grow
            filled
          />
        </v-col>

        <v-col cols="12" lg="4">
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                v-model="formData.center_ids"
                :items="centers"
                :rules="[$data.$globalRules.required]"
                :loading="loadingCenters"
                item-value="const"
                item-text="title"
                label="Cliente - Centro/s"
                dense
                multiple
              />
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="formData.job" label="Puesto de trabajo" dense />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    formData: { type: Object, required: true },
  },
  data: () => ({
    centers: [],
    loadingCenters: null,
  }),
  async mounted() {
    await this.getCenters();
  },
  methods: {
    async getCenters() {
      try {
        this.loadingCenters = true;
        const response = await this.$store.state.currentService.getCenters();
        this.centers = response.data;
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar los centros disponibles | " + e);
      } finally {
        this.loadingCenters = false;
      }
    },
  },
};
</script>

<style scoped></style>
